<template>
  <div class="footer-container">
    <div class="content">
      <div class="logo">
        <img src="~@/assets/image/logo.png" alt="联想金服" />
      </div>
      <div class="contact">
        <h3>联系我们</h3>
        <p>您与我们的每一次交流都将促进我们成长</p>
        <p>商务合作：market@lexhsc.com</p>
        <div class="message-wrapper" @click="show = true">
          <span class="btn">
            欢迎留言
            <img class="img" src="~@/assets/image/arrow.svg" alt="联想金服" />
          </span>
        </div>
      </div>
      <div class="address">
        <div class="item">
          <div class="left">
            <img src="~@/assets/image/address1.png" alt="联想金服" />
          </div>
          <div class="right">
            <h3>服务时间</h3>
            <p>09:30 AM - 18:30 PM</p>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <img src="~@/assets/image/address2.png" alt="联想金服" />
          </div>
          <div class="right">
            <h3>公司地址</h3>
            <p>北京市海淀区中关村东升科技园永泰园B座</p>
          </div>
        </div>
      </div>
      <div class="qr">
        <img src="~@/assets/image/qr.png" alt="联想金服" />
        <p>联想金服官微</p>
      </div>
    </div>
    <div class="record">
      <p>
        @2017-2023，联想金服，All rights reserved，京公网安备 11010802030360号
      </p>
    </div>

    <div class="dialog" v-if="show">
      <div class="title">
        <span>预留贵公司的信息，我们将竭诚为您服务</span>
        <img
          class="close"
          src="~@/assets/image/close.svg"
          alt="联想金服"
          @click="cancel"
        />
      </div>
      <div class="form">
        <p class="tip">
          <span class="red">*</span>
          信息提交后我们将于3个工作日内与您联系
        </p>
        <div class="form-item">
          <p class="name">企业名称</p>
          <input
            type="text"
            v-model="form.enterprise_name"
            class="input"
            placeholder="请输入企业名称"
          />
        </div>
        <div class="form-item">
          <p class="name">联系人姓名</p>
          <input
            type="text"
            v-model="form.name"
            class="input"
            placeholder="请输入联系人姓名"
          />
        </div>
        <div class="form-item">
          <p class="name">联系方式</p>
          <input
            type="text"
            v-model="form.phone"
            class="input"
            placeholder="请输入联系方式"
          />
        </div>
        <textarea
          class="textarea"
          v-model="form.problem"
          placeholder="填写您的合作意向或意见反馈"
        />
      </div>
      <div class="btn-wrapper">
        <div class="cancel-wrapper" @click="cancel">
          <div class="cancel-btn">取消</div>
        </div>
        <div class="submit-wrapper" @click="handleSubmit">
          <span class="btn">提交</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
export default {
  name: 'm-footer',
  data() {
    return {
      show: false,
      form: {
        enterprise_name: '',
        name: '',
        phone: '',
        problem: '',
      },
    }
  },
  methods: {
    cancel() {
      Object.keys(this.form).forEach(key => {
        this.form[key] = ''
      })
      this.show = false
    },
    async handleSubmit() {
      await request.post('/saveMessage', { ...this.form })
      this.$message.success('提交成功')
      this.cancel()
    },
  },
}
</script>

<style scoped lang="scss">
.footer-container {
  box-sizing: border-box;
  padding: 80px 80px 24px 80px;
  background-color: #1e0013;
  color: #fff;

  .content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 56px;

    .contact {
      h3 {
        color: #fff;
        font-size: 14px;
        margin-bottom: 16px;
        opacity: 0.6;
      }
      p {
        font-size: 14px;
        margin-bottom: 32px;
      }

      .message-wrapper {
        .btn {
          display: inline-block;
          width: 132px;
          line-height: 38px;
          text-align: center;
          background: linear-gradient(247deg, #1e47a7 0%, #e1251b 100%);
          border-radius: 6px;
          box-shadow: 0 4px 24px 0 rgba(17, 24, 79, 0.08),
            0 4px 6px 0 rgba(17, 24, 79, 0.15);
          cursor: pointer;

          &:hover {
            background: #ef5828;
          }
        }

        .img {
          position: relative;
          width: 20px;
          top: 5px;
          left: 10px;
        }
      }
    }

    .address {
      .item {
        display: flex;
        .left {
          margin: 0 32px 20px 0;
        }
        .right {
          h3 {
            color: #fff;
            font-size: 14px;
            opacity: 0.6;
            line-height: 30px;
            margin-bottom: 10px;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }

    .qr {
      p {
        margin-top: 20px;
        font-size: 14px;
      }
    }
  }

  .record {
    p {
      text-align: center;
      font-size: 11px;
      color: #fff;
    }
  }

  .dialog {
    position: fixed;
    bottom: 20%;
    left: 50%;
    z-index: 99;
    transform: translateX(-50%);
    width: 506px;
    height: 536px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 4px 24px 0 rgba(17, 24, 79, 0.08),
      0 4px 6px 0 rgba(17, 24, 79, 0.15);

    .title {
      position: relative;
      height: 64px;
      background: linear-gradient(260deg, #1e47a7 0%, #e1251b 100%);
      border-radius: 6px 6px 0 0;
      font-size: 18px;
      line-height: 64px;
      padding: 0 30px;

      .close {
        position: absolute;
        top: 22px;
        right: 30px;
        width: 20px;
        cursor: pointer;
      }
    }

    .form {
      box-sizing: border-box;
      padding: 16px 32px 0;
      color: #4e444e;
      font-size: 14px;

      .red {
        color: #e1251b;
      }

      .tip {
        margin-bottom: 20px;
      }

      .form-item {
        margin-bottom: 21px;
        .name {
          color: #4e444e;
          margin-bottom: 14px;
        }

        .input {
          width: 100%;
          height: 32px;
          border: 1px solid #4e444e;
          border-radius: 6px;
          text-indent: 6px;
        }
      }

      .textarea {
        box-sizing: border-box;
        width: 440px;
        height: 70px;
        border: 1px solid #4e444e;
        border-radius: 6px;
        padding: 10px;
        resize: none;
        overflow-y: hidden;
        margin-bottom: 20px;
      }
    }

    .btn-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-right: 30px;

      .cancel-wrapper {
        padding: 2px;
        background: linear-gradient(to right, #e1251b, #1e47a7);
        border-radius: 6px;
        margin-right: 20px;
        .cancel-btn {
          cursor: pointer;
          width: 64px;
          line-height: 32px;
          font-size: 18px;
          text-align: center;
          background-color: #fff;
          border-radius: 6px;
          color: #000000;
        }
      }

      .submit-wrapper {
        cursor: pointer;
        display: inline-block;
        width: 64px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        font-size: 18px;
        background: linear-gradient(229deg, #1e47a7 0%, #e1251b 100%);
        border-radius: 4px;
        box-shadow: 0 4px 24px 0 rgba(17, 24, 79, 0.08),
          0 4px 6px 0 rgba(17, 24, 79, 0.15);

        span {
          position: relative;
          top: 2px;
        }
      }
    }
  }
}
</style>
