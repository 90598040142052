import Vue from 'vue'
import Vuex from 'vuex'
import storage from '@/utils/storage'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sid: '',
    userInfo: storage.getItem('userInfo') || {},
  },
  getters: {},
  mutations: {
    setSid(state, value) {
      state.sid = value
    },
    setUserInfo(state, value) {
      state.userInfo = value
      storage.setItem('userInfo', value)
    },
    clearAll(state) {
      state.userInfo = null
      state.sid = ''
    },
  },
  actions: {},
  modules: {},
})
