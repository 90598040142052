<template>
  <div class="region parthers" :style="{ background: bgColor }">
    <div class="plate-container">
      <p class="english-name">PARTNERS</p>
      <div class="chinese-name">
        <span class="text">合作伙伴</span>
      </div>
      <div class="bank-list">
        <div class="item">
          <img src="~@/assets/image/bank1.png" alt="联想金服" />
        </div>
        <div class="item">
          <img src="~@/assets/image/bank2.png" alt="联想金服" />
        </div>
        <div class="item">
          <img src="~@/assets/image/bank3.png" alt="联想金服" />
        </div>
        <div class="item">
          <img src="~@/assets/image/bank4.png" alt="联想金服" />
        </div>
        <div class="item">
          <img src="~@/assets/image/bank5.png" alt="联想金服" />
        </div>
        <div class="item">
          <img src="~@/assets/image/bank6.png" alt="联想金服" />
        </div>
        <div class="item">
          <img src="~@/assets/image/bank7.png" alt="联想金服" />
        </div>
        <div class="item">
          <img src="~@/assets/image/bank8.png" alt="联想金服" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'm-parthers',
  props: {
    bgColor: {
      type: String,
      default: '#fff',
    },
  },
}
</script>

<style scoped lang="scss">
.parthers {
  .bank-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      margin-bottom: 16px;
    }
  }
}
</style>
