/**
 * 环境配置封装
 */
const env = process.env.VUE_APP_NODE_ENV || 'prod'

const EnvConfig = {
  dev: {
    baseApi: '/api',
    mockApi: '',
  },
  test: {
    baseApi: '',
    mockApi: '',
  },
  prod: {
    baseApi: '',
    mockApi: '',
  },
}

export default {
  env,
  mock: false,
  namespace: 'website',
  ...EnvConfig[env],
}
