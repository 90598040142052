import { render, staticRenderFns } from "./m-footer.vue?vue&type=template&id=c1e796aa&scoped=true&"
import script from "./m-footer.vue?vue&type=script&lang=js&"
export * from "./m-footer.vue?vue&type=script&lang=js&"
import style0 from "./m-footer.vue?vue&type=style&index=0&id=c1e796aa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.1_vue-template-compiler@2.7.14_webpack@5.75.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1e796aa",
  null
  
)

export default component.exports