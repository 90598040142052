import Vue from 'vue'
import {
  Button,
  Layout,
  Icon,
  Menu,
  Dropdown,
  Avatar,
  Card,
  Table,
  Modal,
  FormModel,
  Input,
  Upload,
  Row,
  Col,
  message,
  Popconfirm,
  Divider,
  Select,
  Pagination,
  Tag,
} from 'ant-design-vue'

Vue.use(Button)
Vue.use(Layout)
Vue.use(Icon)
Vue.use(Menu)
Vue.use(Dropdown)
Vue.use(Avatar)
Vue.use(Card)
Vue.use(Table)
Vue.use(Modal)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Upload)
Vue.use(Row)
Vue.use(Col)
Vue.use(Popconfirm)
Vue.use(Divider)
Vue.use(Select)
Vue.use(Pagination)
Vue.use(Tag)

Vue.prototype.$message = message
