import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Carousel3d from 'vue-carousel-3d'
import MParthers from '@/components/m-parthers'
import MFooter from '@/components/m-footer'
import storage from '@/utils/storage'

// import '@/assets/style/reset.scss'
// import 'normalize.css'
import 'swiper/dist/css/swiper.css'
import '@/assets/style/common.scss'
import 'animate.css'
import '@/plugins/antd-vue'

Vue.prototype.$storage = storage
Vue.use(Carousel3d)
Vue.component('m-parthers', MParthers)
Vue.component('m-footer', MFooter)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
