/**
 * axios 封装
 */
import axios from 'axios'
import storage from './storage'
import router from '../router'
import { message } from 'ant-design-vue'
import store from '../store'

export const ERR_OK = 1

// 创建 axios 实例对象，添加全局配置
const service = axios.create({
  baseURL: '/api',
  timeout: 1000 * 60 * 60,
})

service.interceptors.request.use(
  config => {
    const headers = config.headers
    const token = storage.getItem('userInfo')?.token || ''
    if (!headers.Authorization && token)
      headers.Authorization = 'Bearer ' + token
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

service.interceptors.response.use(
  res => {
    return res.data
  },
  async error => {
    if (error.response.status === 401) {
      message.error('登录过期，请重新登录')
      storage.clearAll()
      store.commit('clearAll')
      await router.push('/user/login')
    }
    console.log(error)
  },
)

export default service
