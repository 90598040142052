import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/home'),
  },
  {
    path: '/lrz',
    name: 'lrz',
    component: () => import(/* webpackChunkName: "lrz" */ '@/views/lrz/lrz'),
  },
  {
    path: '/xbd',
    name: 'xbd',
    component: () => import(/* webpackChunkName: "xbd" */ '@/views/xbd/xbd'),
  },
  {
    path: '/lx',
    name: 'lx',
    component: () => import(/* webpackChunkName: "lx" */ '@/views/lx/lx'),
  },
  {
    path: '/lpst',
    name: 'lpst',
    component: () => import(/* webpackChunkName: "lpst" */ '@/views/lpst/lpst'),
  },
  {
    path: '/zc',
    name: 'zc',
    component: () => import(/* webpackChunkName: "zc" */ '@/views/zc/zc'),
  },
  {
    path: '/gyl',
    name: 'gyl',
    component: () => import(/* webpackChunkName: "gyl" */ '@/views/gyl/gyl'),
  },
  {
    path: '/lyq',
    name: 'lyq',
    component: () => import(/* webpackChunkName: "lyq" */ '@/views/lyq/lyq'),
  },
  {
    path: '/qqxy',
    name: 'qqxy',
    component: () => import(/* webpackChunkName: "qqxy" */ '@/views/qqxy/qqxy'),
  },
  {
    path: '/lxh',
    name: 'lxh',
    component: () => import(/* webpackChunkName: "lxh" */ '@/views/lxh/lxh'),
  },
  {
    path: '/lqx',
    name: 'lqx',
    component: () => import(/* webpackChunkName: "lqx" */ '@/views/lqx/lqx'),
  },
  {
    path: '/jrkj',
    name: 'jrkj',
    component: () => import(/* webpackChunkName: "jrkj" */ '@/views/jrkj/jrkj'),
  },
  {
    path: '/about',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/about/about'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '@/views/news/news'),
  },
  {
    path: '/news/details/:id',
    name: 'news-details',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "news-details" */ '@/views/news/news-details'
      ),
  },
  // 后台部分
  {
    path: '/user',
    component: {
      render: h => h('router-view'),
    },
    redirect: '/user/login',
    children: [
      {
        path: 'login',
        name: 'login',
        component: () =>
          import(
            /* webpackChunkName: "user-login" */ '@/views/user/login/login'
          ),
      },
    ],
  },
  {
    path: '/admin',
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/admin/index'),
    redirect: '/admin/banner-management',
    children: [
      {
        path: 'banner-management',
        name: 'banner-management',
        component: () =>
          import(
            /* webpackChunkName: "banner-management" */ '@/views/admin/banner-management/banner-management'
          ),
      },
      {
        path: 'news-management',
        name: 'news-management',
        component: () =>
          import(
            /* webpackChunkName: "news-management" */ '@/views/admin/news-management/news-management'
          ),
      },
      {
        path: 'notice-management',
        name: 'notice-management',
        component: () =>
          import(
            /* webpackChunkName: "notice-management" */ '@/views/admin/notice-management/notice-management'
          ),
      },
      {
        path: 'message-management',
        name: 'message-management',
        component: () =>
          import(
            /* webpackChunkName: "message-management" */ '@/views/admin/message-management/message-management'
          ),
      },
      {
        path: 'contact-us-management',
        name: 'contact-us-management',
        component: () =>
          import(
            /* webpackChunkName: "contact-us-management" */ '@/views/admin/contact-us-management/contact-us-management'
          ),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.afterEach(to => {
  if (to.name === 'news') {
    window.scrollTo(0, 502)
  } else {
    window.scrollTo(0, 0)
  }
})

export default router
